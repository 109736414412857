import React, { useState } from 'react';

export const AddTodo = (props) => {
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");

    const submit =(e)=>{
         e.preventDefault();
         if(!title || !desc){
             alert("Title or Description cannot be blank");  
         }
         else{
        props.addTodo(title, desc);
        setTitle("");
        setDesc("");
         }
    }
    return (
    <div className="container my-1">
        <h4>Add your Product and Notes</h4>
            <form onSubmit={submit}>
                <div className="mb-1">
                    <label htmlFor="title" className="form-label">Note Heading</label>
                    <input type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} className="form-control" id="title" aria-describedby="emailHelp"/>
                    
                </div>
                    <div className="mb-3">
                        <label htmlFor="desc" className="form-label">About note</label>
                        <input type="text" value={desc} onChange={(e)=>{setDesc(e.target.value)}} className="form-control" id="desc"/>
                    </div>
                            <button type="submit" className="btn btn-sm btn-success">Add Note</button>
            </form>
        
    </div>
    )
}
