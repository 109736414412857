import React from 'react'
import {TodoItem} from "./TodoItem";

export const Todos = (props) => {
    let myStyle={
        minHeight:"70vh",
        margin:"30px auto"
    }
    return (
        <div className="container" style={myStyle}>
            <h5 className="my-1">Notes List</h5>
            {props.todos.length===0? "No Todos to display":
            props.todos.map((todo)=>{
                return (
                
                <TodoItem todo={todo} key={todo.sno} onDelete={props.onDelete}/>
            
                )
            })
              }
            
        </div>
    )
}
