import React from 'react'

export const Footer = () => {

    return (
        <footer className="bg-dark text-light py-1">
            <p className="text-center">
            Copyright &copy;
            <script>document.write(new Date().getFullYear());</script> All rights reserved | This website is made by parent company White-Ocean.online
            </p>
        </footer>
    )
}
